import {Stage} from "src/constants/common";

export const STAGE_NAME = 'beta';
// skip loading react in testing for now. So that proper unit tests can exist cleanly and without excessive load time
// TODO: continue https://taskei.amazon.dev/tasks/V1334014338 and determine a way to use RTL
//  for testing without disabling the ability to test non-visible requirements
if(!global.skipRTL){
    console.log("Stage name " + STAGE_NAME);

    //Sets the right stage based CDN URL for FAB to be consumed via Module Federation.
    // @ts-ignore
    if (STAGE_NAME.toLowerCase() === Stage.prod) {
        (window as any).FAB_URL = 'https://atoz-livehelp-app.amazon.work';
    } else {
        (window as any).FAB_URL = 'https://atoz-livehelp-app.integ.amazon.work';
    }

    import('./bootstrap');
}

export {};